import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useToast,
  VStack,
  Icon
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import homebanner1 from "./../Assets/cday.JPG";
import { FaBookReader } from "react-icons/fa";
import { FaLaptopCode } from "react-icons/fa";
import { FaCertificate } from "react-icons/fa";
import sucessStroy from "./../Assets/success-story.jpg";
import { GrUserExpert } from "react-icons/gr";
import { BiSupport } from "react-icons/bi";
import { AiFillSafetyCertificate } from "react-icons/ai";
import Footer from "../Footer/Footer";
import QuizComponents from "./QuizComponents";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as mod from "./../../url";

const Homepage = () => {
  const [Courses, setCourses] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const fetchCourses = async () => {
    try {
      const { data } = await axios.get(
        `${mod.api_url}/api/course/get_All_course`
      );
      console.log("Fetched courses:", data.Courses); // Inspect the data
      setCourses(data?.Courses);
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast({
        title: "Error fetching courses.",
        description: error.response?.data?.message || "Something went wrong!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const viewCourse = (_id) => {
    navigate(`/get_course/${_id}`);
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    window.scrollTo(0, 0);
  }, []);
  const calculateDiscountedPrice = (price, offer) => {
    if (typeof price !== "number" || typeof offer !== "number") {
      console.error("Invalid price or offer value");
      return price;
    }
    return price - (price * offer) / 100;
  };

  const isOfferValid = (offerDate) => {
    if (!offerDate) return false;
    const today = new Date();
    const offerEndDate = new Date(offerDate);
    return today <= offerEndDate;
  };

  return (
    <>
      <Box position="sticky" top="0" zIndex="100">
        <Navbar />
      </Box>
        {/* <Image src={homebanner} /> */}
      <Box border="2px solid yellow" w="full">
        <Image src={homebanner1} w="100%"/>
      </Box>
      <Box>
        <Box>
          {" "}
          <Container maxWidth="Container.lg" p={4}>
            <h1 style={{fontSize:"3rem", fontWeight:"bold", margin:"20px auto"}}>Our Courses</h1>
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)",xl:"repeat(4, 1fr)", md: "repeat(2, 1fr)",  lg:"repeat(3, 1fr)" }}
              gap={4}
            >
              {Courses &&
                Courses.map((course, index) => (
                  <GridItem
                    key={index}
                    borderRadius="lg"
                    overflow="hidden"
                    border="2px solid black"
                  >
                    <Image
                      src={course.image || "placeholder.jpg"}
                      alt={course.name}
                      height="30vh"
                      width="100%"
                      objectFit="contain"
                    />
                    <VStack p={2} align="center">
                      <Text fontSize="xl" fontWeight="bold">
                        {course.name.slice(0, 70)}
                      </Text>
                      <VStack align="center" display="inline">
                        <Text
                        bg="green.100"
                        padding="0.2em 1.3em"
                        borderRadius="3em"
                          fontSize="xl"
                          fontWeight="bold"
                          textAlign="center"
                          display="block"
                        >
                          Price:
                          <Text as="s" color="red" marginLeft="5px">
                            {" "}
                            {course.price} {course.currency}
                          </Text>
                          {isOfferValid(course.offerDate) && (
                            <p style={{ marginLeft: "10px", color: "green" }}>
                              {" "}
                              {calculateDiscountedPrice(
                                course.price,
                                course.offers
                              ).toFixed(2)}{" "}
                              {course.currency}
                            </p>
                          )}
                        </Text>
                      </VStack>
                      <Flex justifyContent="space-between">
                        <Button
                          colorScheme="blue"
                          padding="0 2em"
                          width="100%"
                          onClick={() => {
                            if (userInfo) {
                              viewCourse(course.slug);
                            } else {
                              toast({
                                title: "Please log in to view this course.",
                                status: "error",
                                duration: 9000,
                                isClosable: true,
                              });
                              window.location.href = "/login";
                            }
                          }}
                        >
                          View Course
                        </Button>
                      </Flex>
                    </VStack>
                  </GridItem>
                ))}
            </Grid>
          </Container>
        </Box>
 {/* Features */}
        <Box p={6}>
      <Text fontWeight="bold" fontSize={{ base: "2xl", md: "4xl" }} textAlign="center" mb={4}>
        Our Features
      </Text>
      <Center>
        <Flex
          width="100%"
          justifyContent="center"
          flexDirection={{ base: "column", md: "row" }}
          gap="1em"
          wrap="wrap"
        >
          {/* Feature 1 */}
          <Box
            className="col-md-3"
            borderRadius="20px"
            paddingTop="1em"
            background="whitesmoke"
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding="1em"
            flexBasis={{ base: "100%", md: "30%" }}
          >
            <Center>
              <FaBookReader
                style={{
                  width: "100px",
                  height: "100px",
                  color: "#f0a500",
                }}
              />
            </Center>
            <Text fontSize="x-large" mt={4}>
              Expert Tutors
            </Text>
            <Text>
              Learn from industry experts who bring real-world experience into the classroom.
            </Text>
          </Box>

          {/* Feature 2 */}
          <Box
            className="col-md-3"
            borderRadius="20px"
            paddingTop="1em"
            background="whitesmoke"
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding="1em"
            flexBasis={{ base: "100%", md: "30%" }}
          >
            <Center>
              <FaLaptopCode
                style={{
                  width: "100px",
                  height: "100px",
                  color: "#f0a500",
                }}
              />
            </Center>
            <Text fontSize="x-large" mt={4}>
              Interactive Courses
            </Text>
            <Text>
              Engage in interactive courses designed to keep you motivated and on track.
            </Text>
          </Box>

          {/* Feature 3 */}
          <Box
            className="col-md-3"
            borderRadius="20px"
            paddingTop="1em"
            background="whitesmoke"
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            padding="1em"
            flexBasis={{ base: "100%", md: "30%" }}
          >
            <Center>
              <FaCertificate
                style={{
                  width: "100px",
                  height: "100px",
                  color: "#f0a500",
                }}
              />
            </Center>
            <Text fontSize="x-large" mt={4}>
              Certified Programs
            </Text>
            <Text>
              Earn certificates to boost your resume and demonstrate your skills with us.
            </Text>
          </Box>
        </Flex>
      </Center>
    </Box>

        {/* this is our Top Quiz area*/}
        <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "10px" }}>
          <div style={{ width: "100%", paddingTop: "10px" }}>
            <QuizComponents />
          </div>
        </Box>

 {/* Why Choose Us*/}
        <Box maxWidth="100%" margin="0 auto" padding="40px" bgGradient="linear(to-r, gray.100, white)">
      <Text textAlign="center" fontSize="4xl" fontWeight="bold" mb="10" color="teal.600">
        Why Choose Us
      </Text>
      
      <Flex
        justifyContent="center"
        alignItems="center"
        wrap="wrap"
        gap="2rem"
        textAlign="center"
        mt="4"
      >
        {/* Expertise Card */}
        <VStack
          spacing={4}
          padding="20px"
          bg="white"
          borderRadius="15px"
          boxShadow="lg"
          _hover={{ transform: "scale(1.05)", transition: "0.3s ease-in-out" }}
          width={{ base: "full", md: "30%" }}
        >
          <Center>
            <Icon as={GrUserExpert} boxSize="100px" color="teal.400" />
          </Center>
          <Text fontSize="2xl" fontWeight="semibold" color="teal.600">Expertise</Text>
          <Text fontSize="md" color="gray.600">
            We have a team of experienced professionals who are experts in their fields, providing you with top-notch educational resources and guidance.
          </Text>
        </VStack>

        {/* Support Card */}
        <VStack
          spacing={4}
          padding="20px"
          bg="white"
          borderRadius="15px"
          boxShadow="lg"
          _hover={{ transform: "scale(1.05)", transition: "0.3s ease-in-out" }}
          width={{ base: "full", md: "30%" }}
        >
          <Center>
            <Icon as={BiSupport} boxSize="100px" color="teal.400" />
          </Center>
          <Text fontSize="2xl" fontWeight="semibold" color="teal.600">Support</Text>
          <Text fontSize="md" color="gray.600">
            Our dedicated support team is here to help you with any questions or issues you may have, ensuring a smooth learning experience.
          </Text>
        </VStack>

        {/* Quality Card */}
        <VStack
          spacing={4}
          padding="20px"
          bg="white"
          borderRadius="15px"
          boxShadow="lg"
          _hover={{ transform: "scale(1.05)", transition: "0.3s ease-in-out" }}
          width={{ base: "full", md: "30%" }}
        >
          <Center>
            <Icon as={AiFillSafetyCertificate} boxSize="100px" color="teal.400" />
          </Center>
          <Text fontSize="2xl" fontWeight="semibold" color="teal.600">Quality</Text>
          <Text fontSize="md" color="gray.600">
            We provide high-quality materials and tests that are regularly updated to keep up with the latest standards and trends in education.
          </Text>
        </VStack>
      </Flex>
    </Box>

        {/* this is why chose us area*/}
        <Box style={{ maxWidth: "100%", margin: "0 auto", padding: "20px" }}>
          <div
            className="row chose-us"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <Text
              textAlign="center"
              fontSize="xx-large"
              fontWeight="700"
            >
              Success Story
            </Text>
            <div className="col-md-2">
              <Image src={sucessStroy} width="100%" height="200px" />
            </div>
            <div className="col-md-8" style={{ padding: "20px" }}>
              <p>
                After struggling with traditional learning methods, we
                discovered GeniusGrid. The personalized learning paths and
                interactive tools transformed our study habits. Within months,
                our grades improved dramatically, and we regained our confidence
              </p>
              <p>
                Our journey with GeniusGrid didn't just help us excel
                academically; it also reignited our passion for learning. Today,
                we are not only a top student but also an enthusiastic advocate
                for innovative education, inspiring our peers to join GeniusGrid
                and experience the same success.
              </p>
            </div>
          </div>
        </Box>

        {/* this is learn more about uss area*/}
        <Box style={{ width: "100%", margin: "0 auto", padding: "20px" }}>
          <div
            className="row chose-us"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Text
              textAlign="center"
              fontSize="xx-large"
              fontWeight="700"
            >
              Learn More About Us
            </Text>
            <Center>
              <div
                style={{
                  textAlign: "center",
                  width: "100vw",
                }}
              >
                <iframe
                  src="https://www.youtube.com/embed/KgcVsemXvk0?si=ubt9e6ACwntKMXE0"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ width: "80%",height:"40vh", border:"5px solid black", margin:"auto"}}
                ></iframe>
              </div>
            </Center>
          </div>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Homepage;
